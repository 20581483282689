import styles from "./ProductDescription.scss";
import PropTypes from "prop-types";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";

export function ProductDetails({
  productDescriptionVisible,
  productDescription,
}) {
  const {
    product: { productCode },
    getTranslation,
  } = useRuntimeConstants();

  return (
    <>
      {productDescriptionVisible && (
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: productDescription }}
        />
      )}
      <p className={styles.productCode}>
        {`${getTranslation(
          "pdp_description_product_code_title"
        )}: ${productCode}`}
      </p>
    </>
  );
}

ProductDetails.propTypes = {
  productDescriptionVisible: PropTypes.bool,
  productDescription: PropTypes.string,
};
