import { useState, useRef, useEffect, useCallback } from "react";
import classnamesBind from "classnames/bind";

import styles from "./SustainabilityInfoContainer.scss";
import Modal from "../modal/Modal";
import {
  MODAL_TYPE,
  SUSTAINABILITY_INFO_CONFIG,
  TEXT_SIZES,
} from "../../constants";
import SustainabilityInfo from "./SustainabilityInfo";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { getFeature } from "../../utils/features";
import {
  allVariantsPartnerFulfils,
  isVariantPartnerFulfils,
  getVariantsColourWayId,
} from "../../../utils/variantUtils";
import { useFeaturesReady } from "../../context/featuresReady/FeaturesReadyContext";
import { useVariant } from "../../context/variant/VariantContext";
import { fireSustainabilityInfoLinkClick } from "../../analytics/pageInteraction";
import { useStockPrice } from "../../context/stockPrice/StockPriceContext";
import Button from "../buttons/button/Button";
import { BUTTON_TYPES } from "../buttons/button/ButtonTypes";

const classnames = classnamesBind.bind(styles);

function isValidSustainabilityStore({ storeId }) {
  return SUSTAINABILITY_INFO_CONFIG.find(
    (sustainabilityInfoStore) =>
      sustainabilityInfoStore.store.toLowerCase() === storeId?.toLowerCase()
  );
}

export default function SustainabilityInfoContainer() {
  const { products: product } = useStockPrice();
  const { getTranslation, webContext } = useRuntimeConstants();
  const { areFeaturesReady } = useFeaturesReady();
  const modalId = "sustainabilityInfoModal";
  const buttonRef = useRef();
  const [modalOpen, setModalOpen] = useState(false);
  const [shouldRenderSustainabilityInfo, setShouldRenderSustainabilityInfo] =
    useState(false);
  const [
    shouldShowSustainabilityInfoLink,
    setShouldShowSustainabilityInfoLink,
  ] = useState(true);
  const [contentLoaded, setContentLoaded] = useState(false);
  const { selectedVariantId } = useVariant();

  useEffect(() => {
    if (areFeaturesReady && product) {
      setShouldRenderSustainabilityInfo(
        !!isValidSustainabilityStore({ storeId: webContext?.storeId }) &&
          !allVariantsPartnerFulfils(product.variants) &&
          getFeature("pdp-sustainability-info").isEnabled()
      );
    }
  }, [product, areFeaturesReady, webContext]);

  useEffect(() => {
    setShouldShowSustainabilityInfoLink(
      selectedVariantId === null ||
        !isVariantPartnerFulfils(product.variants, selectedVariantId)
    );
  }, [product, selectedVariantId]);

  const onSustainabilityInfoReceived = useCallback((isSuccess) => {
    fireSustainabilityInfoLinkClick(isSuccess);
    setContentLoaded(true);
  }, []);

  const onModalClose = () => {
    setModalOpen(false);
    setContentLoaded(false);
  };

  if (!shouldRenderSustainabilityInfo) {
    return null;
  }

  const colourWayId = getVariantsColourWayId(
    product.variants,
    selectedVariantId
  );

  return (
    <>
      <Button
        buttonRef={buttonRef}
        additionalClasses={classnames(styles.sustainabilityInfoButton, {
          hideButton: !shouldShowSustainabilityInfoLink,
        })}
        size={TEXT_SIZES.leavesden3}
        dataTestId="sustainabilityInfoButton"
        handleClick={() => setModalOpen(true)}
        type={BUTTON_TYPES.naked}
      >
        {getTranslation("pdp_environmental_quality")}
      </Button>
      <Modal
        title={getTranslation("pdp_environmental_quality_info_title")}
        open={modalOpen}
        contentLoaded={contentLoaded}
        closeModal={onModalClose}
        focusOnClose={buttonRef.current}
        modalId={modalId}
        modalType={MODAL_TYPE.SIDE}
      >
        {modalOpen && (
          <SustainabilityInfo
            colourWayId={colourWayId}
            browseLanguage={webContext.browseLanguage}
            onInfoReceived={onSustainabilityInfoReceived}
          />
        )}
      </Modal>
    </>
  );
}
