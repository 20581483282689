import { setShippingSourcesForProduct } from "../../analytics/pageLoad";
import PropTypes from "prop-types";
import styles from "./DeliveryAndReturns.scss";
import classnamesBind from "classnames/bind";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import {
  allVariantsPrimarySource,
  getInStockVariants,
} from "../../../utils/variantUtils";
import SoldShippedHeader from "./soldShippedHeader/SoldShippedHeader";
import { getProductSourceSellerInfo } from "../../../utils/sourceSellerHelper";
import { useVariant } from "../../context/variant/VariantContext";
import { useProduct } from "../../context/product/ProductContext";
import { fireAfsAnalytics } from "../../analytics/pageInteraction";

const classnames = classnamesBind.bind(styles);

function fireSourcingAnalytics({
  selectedVariant,
  product,
  sellerId,
  sellerLabel,
  sourceId,
  sourceLabel,
  selectedOrAvailableVariants,
}) {
  if (!selectedVariant) {
    setShippingSourcesForProduct({
      fulfillerId: allVariantsPrimarySource(product.variants)
        ? "primary"
        : sourceId?.toLowerCase(),
      sellerId,
      hasShippedByLabel: !!(sellerLabel || sourceLabel),
      productId: product.id,
      variants: selectedOrAvailableVariants,
    });
  }
}

const getSelectedOrAvailableVariants = ({ selectedVariant, product }) => {
  if (selectedVariant) {
    return [selectedVariant];
  }

  if (product) {
    return getInStockVariants(product.variants);
  }
};

export default function DeliveryAndReturnsReact({
  toggleShippingRestrictions,
}) {
  const {
    getTranslation,
    deliveryApiEndpoint,
    features: { showReturnsMessage },
    deliveryAndReturnsTrackingValue,
    webContext: { browseCountry },
  } = useRuntimeConstants();
  const { product } = useProduct();
  const { selectedVariant } = useVariant();
  const selectedOrAvailableVariants = getSelectedOrAvailableVariants({
    selectedVariant,
    product,
  });
  const {
    isAllAsosSold,
    isConsistent,
    sourceId,
    sellerId,
    sellerLabel,
    sourceLabel,
    isPartnerFulfils,
  } = getProductSourceSellerInfo({
    variants: selectedOrAvailableVariants,
  });
  const deliveryAndReturnsLink = `${deliveryApiEndpoint}${
    deliveryAndReturnsTrackingValue
      ? `#feemsg=${encodeURIComponent(deliveryAndReturnsTrackingValue)}`
      : ""
  }`;

  const openShippingRestrictionsModal = (event) => {
    toggleShippingRestrictions(
      true,
      product.shippingRestrictions?.shippingRestrictionsIncludedCountries,
      event.target
    );
  };

  const getNonGbMessages = () => ({
    termsLink: getTranslation("delivery_and_returns_policy"),
    delivery: isConsistent
      ? `${getTranslation(
          "delivery_information_threshold_message_qualifying_orders1"
        )}.`
      : getTranslation(
          "delivery_information_threshold_message_no_variant_selected"
        ),
  });

  const getNoSelectedVariantMessages = () => ({
    delivery: isAllAsosSold
      ? "Free delivery on orders over £40.00. Standard delivery £4.50"
      : "Select a size to see delivery options.",
    termsLink: "View Delivery & Returns rates",
  });

  const getSelectedVariantMessages = () => ({
    delivery: !isAllAsosSold
      ? `${getTranslation(
          "delivery_information_threshold_message_qualifying_orders1"
        )}.`
      : "Free delivery on orders over £40.00. Standard delivery £4.50",
    termsLink: !isAllAsosSold
      ? "View our Delivery & Returns Policy"
      : "View Delivery & Returns rates",
  });

  const getMessages = () => {
    if (browseCountry !== "GB") {
      return getNonGbMessages();
    }

    if (!selectedVariant) {
      return getNoSelectedVariantMessages();
    }

    return getSelectedVariantMessages();
  };

  const renderDeliveryMessage = () => {
    const { delivery } = getMessages();
    return (
      <div
        data-testid="deliveryAndReturns__deliveryMessage"
        className={classnames(styles.row, styles.deliveryMessage)}
        aria-live="polite"
      >
        <span className={styles.iconContainer}>
          <span className={`${styles.icon} product-delivery-van`} />
        </span>
        {delivery}
      </div>
    );
  };

  const renderReturnsLabel = () =>
    showReturnsMessage && (
      <div
        data-testid="deliveryAndReturns__returnsLabel"
        className={styles.row}
      >
        <span className={styles.iconContainer}>
          <span className={classnames(styles.icon, "product-returns-box")} />
        </span>
        {getTranslation(
          isPartnerFulfils ? "returns_message_partner" : "returns_message_asos"
        )}
      </div>
    );

  const renderTerms = () => {
    const { termsLink } = getMessages();
    return (
      <div
        data-testid="deliveryAndReturns__terms"
        className={classnames(styles.row, styles.noIcon, styles.terms)}
      >
        <span>
          <a
            data-testid="deliveryAndReturns__termsLink"
            href={deliveryAndReturnsLink}
            target="_blank"
            aria-label={`${termsLink}. ${getTranslation(
              "generic_opens_new_window"
            )}.`}
            rel="noreferrer"
          >
            <span className={styles.termsLinkText} aria-hidden={true}>
              {termsLink}
              <span className={`${styles.icon} product-new-page-small`} />
            </span>
          </a>
        </span>
      </div>
    );
  };

  const renderShippingRestrictions = () => {
    if (product.shippingRestrictions.shippingRestrictionsVisible) {
      return (
        <div
          data-testid="deliveryAndReturns__shippingRestrictions"
          className={classnames(styles.row, styles.shippingRestrictions)}
          aria-live="polite"
        >
          <button
            data-testid="deliveryAndReturns__shippingRestrictionsButton"
            onClick={openShippingRestrictionsModal}
          >
            {product.shippingRestrictions.shippingRestrictionsLabel}
          </button>
        </div>
      );
    }
  };

  if (!product) {
    return null;
  }

  fireSourcingAnalytics({
    selectedVariant,
    product,
    sellerId,
    sellerLabel,
    sourceId,
    sourceLabel,
    selectedOrAvailableVariants,
  });

  return (
    <div
      className={styles.container}
      data-testid="deliveryAndReturns__container"
    >
      <div className={classnames(styles.row, styles.shippingLabel)}>
        <SoldShippedHeader fireSellerInfoAnalytics={fireAfsAnalytics} />
      </div>
      {renderDeliveryMessage()}
      {renderReturnsLabel()}
      {renderTerms()}
      {renderShippingRestrictions()}
    </div>
  );
}

DeliveryAndReturnsReact.propTypes = {
  toggleShippingRestrictions: PropTypes.func.isRequired,
};
