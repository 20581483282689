import PropTypes from "prop-types";
import { useIsInStockContext } from "../../context/isInStock/IsInStockContext";

export default function IfStockStateReady({ children }) {
  const { isStockStateReady } = useIsInStockContext();

  return isStockStateReady ? children : null;
}

IfStockStateReady.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
