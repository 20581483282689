/* eslint-disable jsx-a11y/anchor-is-valid */
/* https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/issues/906 */

import classnamesBind from "classnames/bind";
import { formatUrl, getProductUrl } from "../../../utils/url";
import styles from "./FacetThumbnail.scss";
import { PropTypes } from "prop-types";
import { useRuntimeConstants } from "../../../context/runtimeConstants/RuntimeConstantsContext";
import getImageSource from "../../../../common/imagePresets/imagePresets";
import SelectableTile, {
  SELECTABLE_TILE_TYPES,
} from "../../selectableTile/SelectableTile";

const classnames = classnamesBind.bind(styles);
const WARM_THUMBNAIL_WIDTH = 75;

const FacetThumbnail = ({ productFacet, isCarousel, onFocus }) => {
  const { getTranslation } = useRuntimeConstants();
  const {
    productId,
    isSelected,
    isInStock,
    brandName,
    imageUrl,
    name,
    description,
  } = productFacet;

  const toggleThumbnailFocus = (toggleOn) => {
    if (isCarousel || isSelected || !onFocus) {
      return;
    }

    onFocus({ productFacet, isFocused: toggleOn });
  };

  const getImage = () => (
    <>
      {!isInStock && (
        <div className={styles.oosOverlay} data-testid={"oosOverlay"}>
          <div className={styles.oosOverlayLine} />
        </div>
      )}
      <img
        src={getImageSource(
          formatUrl(imageUrl),
          "thumbnail",
          WARM_THUMBNAIL_WIDTH
        )}
        className={classnames({
          smallImage: !isCarousel,
          largeImage: isCarousel,
        })}
        alt={description}
      />
    </>
  );

  const createAriaLabel = () => {
    if (!isInStock) {
      return `${description}. ${getTranslation(
        "pdp_grouping_accessibility_colour_oos"
      )}.`;
    } else {
      return description;
    }
  };

  const createDataTestId = () =>
    `facetThumbnail${isSelected ? `--selected` : ``}`;

  return (
    <SelectableTile
      isSelected={isSelected}
      type={
        isCarousel
          ? SELECTABLE_TILE_TYPES.SIDE_SCROLL
          : SELECTABLE_TILE_TYPES.GRID
      }
      hasFocus={toggleThumbnailFocus}
      testId={createDataTestId()}
    >
      {!isSelected ? (
        <a
          href={`${getProductUrl(
            brandName,
            name,
            productId
          )}#ctaref-more+colours+swatches&featureref1-more+colours+swatches`}
          className={styles.facetThumbnailLink}
          onFocus={() => onFocus && onFocus({ productFacet })}
          aria-label={createAriaLabel()}
        >
          {getImage()}
        </a>
      ) : (
        <a
          role="link"
          aria-disabled="true"
          aria-label={createAriaLabel()}
          aria-current={isSelected ? "page" : null}
          className={classnames(
            styles.facetThumbnailLink,
            styles.facetThumbnailLinkDisabled
          )}
        >
          {getImage()}
        </a>
      )}
    </SelectableTile>
  );
};

FacetThumbnail.propTypes = {
  isCarousel: PropTypes.bool,
  onFocus: PropTypes.func,
  productFacet: PropTypes.shape({
    name: PropTypes.string,
    productId: PropTypes.number,
    description: PropTypes.string,
    imageUrl: PropTypes.string,
    isSelected: PropTypes.bool,
    isInStock: PropTypes.bool,
    brandName: PropTypes.string,
  }).isRequired,
};

export default FacetThumbnail;
