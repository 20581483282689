import { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useProduct } from "../product/ProductContext";
import { setVariantForFacet } from "./facetVariants";
import { useIsInStockContext } from "../isInStock/IsInStockContext";
import {
  getPreselectedVariant,
  shouldDisplayVariantSelectBasedOnVariants,
} from "../../utils/product";
import { getInStockVariants } from "../../../utils/variantUtils";
import { useBackInStockVariantMessage } from "./useBackInStockVariantMessage";
import { useRuntimeConstants } from "../runtimeConstants/RuntimeConstantsContext";
import { usePreviousBackInStock } from "../backInStock/BackInStockContext";

const VariantContext = createContext({});

const selectedVariantFromProduct = ({ product, selectedVariantId }) => {
  return product?.variants?.find(
    ({ variantId }) => variantId === selectedVariantId
  );
};

const isPreSelectable = ({ isInStock, oosVariantsAreSelectable, product }) => {
  if (!oosVariantsAreSelectable) {
    return isInStock && product && getInStockVariants(product?.variants).length;
  }
  return !!product?.variants?.length;
};

export const VariantContextProvider = ({
  children,
  fireSourcingLocationAnalytics = () => {},
}) => {
  const { product } = useProduct();
  const { isInStock } = useIsInStockContext();
  const { oosVariantsAreSelectable } = useRuntimeConstants();
  const [selectedVariantId, setSelectedVariantId] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const { previousBackInStockVariantId } = usePreviousBackInStock();

  useEffect(() => {
    if (!isPreSelectable({ oosVariantsAreSelectable, isInStock, product })) {
      return;
    }

    function setSelectedVariantIdIfAllowed({ isInStock, variantId }) {
      if (isPreSelectable({ oosVariantsAreSelectable, isInStock, product })) {
        setSelectedVariantId(variantId);
      }
    }

    const selectedVariant = getPreselectedVariant(
      product,
      previousBackInStockVariantId
    );

    if (selectedVariant) {
      setSelectedVariantIdIfAllowed(selectedVariant);
      return;
    }

    if (
      !shouldDisplayVariantSelectBasedOnVariants(
        product,
        oosVariantsAreSelectable
      )
    ) {
      const variantsForSelection = oosVariantsAreSelectable
        ? product.variants
        : getInStockVariants(product.variants);

      setSelectedVariantId(variantsForSelection[0].variantId);
    } else {
      setSelectedVariantId(null);
    }
  }, [
    product,
    isInStock,
    oosVariantsAreSelectable,
    previousBackInStockVariantId,
  ]);

  useEffect(() => {
    if (!product?.variants?.length) {
      return;
    }

    const selectedVariant = selectedVariantFromProduct({
      product,
      selectedVariantId,
    });
    setSelectedVariant(selectedVariant);

    if (selectedVariant) {
      setVariantForFacet({ variant: selectedVariant });
    }
  }, [product, selectedVariantId]);

  useEffect(() => {
    if (selectedVariant) {
      fireSourcingLocationAnalytics({ selectedVariant });
    }
  }, [fireSourcingLocationAnalytics, selectedVariant]);

  useBackInStockVariantMessage({ selectedVariant });

  return (
    <VariantContext.Provider
      value={{
        selectedVariantId,
        setSelectedVariantId,
        selectedVariant,
      }}
    >
      {children}
    </VariantContext.Provider>
  );
};

VariantContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  fireSourcingLocationAnalytics: PropTypes.func,
};

export const useVariant = () => useContext(VariantContext);
