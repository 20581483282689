import styles from "./SelectableTile.scss";
import classnamesBind from "classnames/bind";
import PropTypes from "prop-types";

const classnames = classnamesBind.bind(styles);
export const SELECTABLE_TILE_TYPES = {
  SIDE_SCROLL: "side-scroll",
  GRID: "grid",
};

const SelectableTile = ({ isSelected, type, hasFocus, testId, children }) => {
  const handleTileFocus = (focused) => hasFocus && hasFocus(focused);

  return (
    <li
      className={classnames(styles.tile, {
        selected: isSelected,
        [type]: type,
      })}
      onMouseEnter={() => handleTileFocus(true)}
      onMouseLeave={() => handleTileFocus(false)}
      onFocus={() => handleTileFocus(true)}
      onBlur={() => handleTileFocus(false)}
      data-testid={testId}
    >
      {children}
    </li>
  );
};

SelectableTile.propTypes = {
  isSelected: PropTypes.bool,
  hasFocus: PropTypes.func,
  testId: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(Object.keys(SELECTABLE_TILE_TYPES)).isRequired,
  isTab: PropTypes.bool,
};

export default SelectableTile;
