import { useEffect, useState } from "react";
import getWindow from "../../utils/window";
import { useRuntimeConstants } from "../runtimeConstants/RuntimeConstantsContext";
import { removeSessionStorageItem } from "../../utils/storage";
import { BACK_IN_STOCK_VARIANT_IDS_SESSION_KEY } from "../../constants";
import { setEvents } from "../../analytics/events";

const BACK_IN_STOCK_AFFID = "28154";

function showNotification({ messageDisplayed, urlParams, selectedVariant }) {
  return (
    !messageDisplayed &&
    urlParams.affid === BACK_IN_STOCK_AFFID &&
    !selectedVariant.isInStock
  );
}

export function useBackInStockVariantMessage({ selectedVariant }) {
  const { getTranslation, urlParams } = useRuntimeConstants();
  const [messageDisplayed, setMessageDisplayed] = useState(false);

  useEffect(() => {
    if (!selectedVariant) {
      return;
    }

    if (showNotification({ messageDisplayed, selectedVariant, urlParams })) {
      getWindow().asos.eventBus.emit("notificationBar", {
        type: "alert",
        copy: getTranslation("back_in_stock_sold_out"),
        refocusId: "variantSelector",
      });

      setEvents({ events: ["event184"], trackingName: "postPageLoad" });

      removeSessionStorageItem(BACK_IN_STOCK_VARIANT_IDS_SESSION_KEY);
    }

    if (!messageDisplayed) {
      setMessageDisplayed(true);
    }
  }, [selectedVariant, messageDisplayed, urlParams, getTranslation]);
}
