import { useState } from "react";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import DeliveryAndReturnsReact from "../../components/deliveryAndReturns/DeliveryAndReturnsReact";
import ShippingRestrictionsModal from "../../components/shippingRestrictionsModal/ShippingRestrictionsModal";
import { useProduct } from "../../context/product/ProductContext";

export default function DeliveryAndReturnsReactContainer() {
  const { isMixAndMatch } = useRuntimeConstants();
  const { product } = useProduct();
  const [
    shippingRestrictionsIncludedCountries,
    setShippingRestrictionsIncludedCountries,
  ] = useState([]);
  const [openShippingRestrictions, setOpenShippingRestrictions] =
    useState(false);
  const [
    shippingRestrictionsModalFocusOnClose,
    setShippingRestrictionsModalFocusOnClose,
  ] = useState(null);

  if (isMixAndMatch || !product) {
    return null;
  }

  const toggleShippingRestrictions = (
    openShippingRestrictions,
    shippingRestrictionsIncludedCountries,
    shippingRestrictionsModalFocusOnClose
  ) => {
    setOpenShippingRestrictions(openShippingRestrictions);

    setShippingRestrictionsIncludedCountries(
      shippingRestrictionsIncludedCountries ?? []
    );

    shippingRestrictionsModalFocusOnClose &&
      setShippingRestrictionsModalFocusOnClose(
        shippingRestrictionsModalFocusOnClose
      );
  };

  return (
    <>
      <DeliveryAndReturnsReact
        toggleShippingRestrictions={toggleShippingRestrictions}
      />
      <ShippingRestrictionsModal
        includedCountries={shippingRestrictionsIncludedCountries}
        open={openShippingRestrictions}
        focusOnClose={shippingRestrictionsModalFocusOnClose}
        toggleShippingRestrictions={toggleShippingRestrictions}
      />
    </>
  );
}
