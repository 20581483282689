import PropTypes from "prop-types";
import styles from "./IngredientsButton.scss";
import { fireIngredientsButtonClickAnalytics } from "../../analytics/pageInteraction";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";

const IngredientsButton = ({ onClick }) => {
  const { getTranslation } = useRuntimeConstants();

  const handleClick = (event) => {
    onClick(event);
    fireIngredientsButtonClickAnalytics();
  };

  return (
    <button
      className={styles.ingredientsButton}
      onClick={handleClick}
      data-testid="ingredients-button"
    >
      <span className="product-ingredients-icon" />
      <span>{getTranslation("pdp_description_ingredients_title")}</span>
    </button>
  );
};

export default IngredientsButton;

IngredientsButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
