import PropTypes from "prop-types";
import classnamesBind from "classnames/bind";
import styles from "./Spacing.scss";

const classnames = classnamesBind.bind(styles);

export const SPACING = {
  EIGHT: "eight",
  TWELVE: "twelve",
  SIXTEEN: "sixteen",
  TWENTY: "twenty",
  TWENTYFOUR: "twentyfour",
};

export default function Spacing({ size, excludeFirst, children }) {
  return (
    <span className={classnames(styles[size], { excludeFirst })}>
      {children}
    </span>
  );
}

Spacing.propTypes = {
  size: PropTypes.oneOf(Object.values(SPACING)).isRequired,
  excludeFirst: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
