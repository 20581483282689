import { useEffect, useState } from "react";
import { PropTypes } from "prop-types";

import ErrorPage from "../errorPage/ErrorPage";
import Hr from "../hr/Hr";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import getVariantSustainabilityData from "../../api/sustainabilityApi";
import styles from "./SustainabilityInfo.scss";
import { SUSTAINABILITY_INFO_CONFIG } from "../../constants";

const SustainabilityInfoSection = ({
  type,
  browseLanguage,
  titleKey,
  children,
}) => {
  const { getTranslation } = useRuntimeConstants();
  const countryInformation = SUSTAINABILITY_INFO_CONFIG.find((information) => {
    return (
      information.browseLanguage.toLowerCase() === browseLanguage.toLowerCase()
    );
  })?.includedInformation;

  if (!(countryInformation?.includes(type) && children)) {
    return null;
  }

  return (
    <>
      {titleKey && <h4>{getTranslation(titleKey)}</h4>}
      {children}
    </>
  );
};

export default function SustainabilityInfo({
  colourWayId,
  browseLanguage,
  onInfoReceived,
}) {
  const [information, setInformation] = useState(null);
  const [error, setError] = useState(false);
  const { getTranslation } = useRuntimeConstants();

  useEffect(() => {
    const onError = () => {
      onInfoReceived && onInfoReceived(false);
      setError(true);
    };
    if (!colourWayId || !browseLanguage) {
      onError();
      return;
    }

    setError(false);

    getVariantSustainabilityData(colourWayId, browseLanguage)
      .then((sustainabilityInformation) => {
        onInfoReceived && onInfoReceived(true);
        setInformation(sustainabilityInformation);
      })
      .catch(onError);
  }, [colourWayId, browseLanguage, onInfoReceived]);

  if (error) {
    return (
      <ErrorPage
        text={getTranslation("pdp_environmental_quality_info_error")}
      />
    );
  }

  return (
    information && (
      <article className={styles.container}>
        <h3>{getTranslation("pdp_environmental_quality_info_subtitle")}</h3>
        <Hr />
        {buildSustainabilitySections({ ...information }, browseLanguage)}
        <br></br>
        {information.lastUpdatedDate && (
          <p>
            {`${getTranslation(
              "pdp_environmental_quality_last_updated_date"
            )}: ${new Date(information.lastUpdatedDate).toLocaleDateString(
              information.language
            )}`}
          </p>
        )}
      </article>
    )
  );
}

function mapSustainabilityData({ data, type }) {
  if (!data) {
    return null;
  }

  if (type === "images") {
    return (
      <div className={styles.images} data-testid="logoContainer">
        {data.map(({ attributeName, attributeValue }) => {
          return (
            <img
              src={attributeValue}
              alt={attributeName}
              key={attributeName}
            ></img>
          );
        })}
      </div>
    );
  }

  return data
    .filter(({ attributeName, attributeValues }) => {
      return (
        attributeName &&
        Array.isArray(attributeValues) &&
        attributeValues.some((attributeValue) => attributeValue?.length > 0)
      );
    })
    .map(({ attributeName, attributeValues }) => {
      const content = `${attributeName}: ${attributeValues.join(", ")}`;
      return <p key={content}>{content}</p>;
    });
}

function buildSustainabilitySections(
  { product, packaging, logoUrls },
  browseLanguage
) {
  return (
    <>
      {product?.length > 0 && (
        <SustainabilityInfoSection
          browseLanguage={browseLanguage}
          type="product"
          titleKey={"pdp_environmental_product_info"}
        >
          {mapSustainabilityData({ data: product })}
        </SustainabilityInfoSection>
      )}
      {packaging?.length > 0 && (
        <SustainabilityInfoSection
          browseLanguage={browseLanguage}
          type="packaging"
          titleKey={"pdp_environmental_packaging_info"}
        >
          {mapSustainabilityData({ data: packaging })}
        </SustainabilityInfoSection>
      )}
      {logoUrls?.length > 0 && (
        <SustainabilityInfoSection
          browseLanguage={browseLanguage}
          type="images"
        >
          {mapSustainabilityData({ data: logoUrls, type: "images" })}
        </SustainabilityInfoSection>
      )}
    </>
  );
}

SustainabilityInfoSection.propTypes = {
  type: PropTypes.string.isRequired,
  browseLanguage: PropTypes.string.isRequired,
  titleKey: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

SustainabilityInfo.propTypes = {
  colourWayId: PropTypes.string.isRequired,
  browseLanguage: PropTypes.string.isRequired,
  onInfoReceived: PropTypes.func,
};
