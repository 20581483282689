import { useEffect, useState } from "react";
import { Accordion } from "@asosteam/asos-web-component-library-accordion";
import styles from "./ProductDescription.scss";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { getFeature } from "../../utils/features";
import { fireProductDetailsAccordionItemOpened } from "../../analytics/pageInteraction";
import { useFeaturesReady } from "../../context/featuresReady/FeaturesReadyContext";
import { GenericAccordionItem } from "./GenericAccordionItem";
import { ProductDetails } from "./ProductDetails";
import { AboutMe } from "./AboutMe";
import { MoreInfo } from "./MoreInfo";
import { SizeAndFit } from "./SizeAndFit";

export default function ProductDescription() {
  const {
    product: { pdpLayout, showCmaTitle },
    productDescription,
    getTranslation,
  } = useRuntimeConstants();
  const [isAccordionTrackingEnabled, setIsAccordionTrackingEnabled] =
    useState(false);
  const { areFeaturesReady } = useFeaturesReady();

  useEffect(() => {
    if (areFeaturesReady) {
      setIsAccordionTrackingEnabled(
        getFeature("pdp-accordion-tracking").isEnabled()
      );
    }
  }, [areFeaturesReady]);

  const onItemClickHandler = (_, itemClicked, isOpen) => {
    if (isOpen && isAccordionTrackingEnabled) {
      const { analyticsId } = itemClicked;
      fireProductDetailsAccordionItemOpened(analyticsId);
    }
  };

  const getAboutMeTitleTranslationKey = () => {
    switch (true) {
      case pdpLayout === "Face & Body":
        return "facebody_pdp_info_howtouse";
      case showCmaTitle:
        return "pdp_description_about_me_title_cma";
      default:
        return "pdp_description_about_me_title";
    }
  };

  return (
    <div className={styles.container} id="productDescription">
      <Accordion
        items={buildAccordionItems(
          productDescription,
          getTranslation,
          getAboutMeTitleTranslationKey
        )}
        onItemClick={onItemClickHandler}
        headingElement="h2"
      />
    </div>
  );
}

function buildAccordionItems(
  productDescription,
  getTranslation,
  getAboutMeTitleTranslationKey
) {
  return [
    {
      id: "productDescriptionDetails",
      analyticsId: "Product Details",
      title: getTranslation("pdp_description_product_details_title"),
      component: ProductDetails(productDescription),
    },
    {
      id: "productDescriptionBrand",
      analyticsId: "Brand",
      title: getTranslation("pdp_description_brand_title"),
      component: GenericAccordionItem({
        content: productDescription.brandDescription,
        isVisible: productDescription.brandDescriptionVisible,
      }),
    },
    {
      id: "productDescriptionSizeAndFit",
      analyticsId: "Size & Fit",
      title: getTranslation("pdp_description_size_and_fit_title"),
      component: SizeAndFit(productDescription),
    },
    {
      id: "productDescriptionCareInfo",
      analyticsId: "Care Info",
      title: getTranslation("pdp_description_care_info_title"),
      component: GenericAccordionItem({
        content: productDescription.careInfo,
        isVisible: productDescription.careInfoVisible,
      }),
    },
    {
      id: "productDescriptionAboutMe",
      analyticsId: "About Me",
      title: getTranslation(getAboutMeTitleTranslationKey()),
      component: AboutMe(productDescription),
    },
    {
      id: "productDescriptionMoreInfo",
      analyticsId: "More Info",
      title: getTranslation("pdp_description_more_info_title"),
      component: MoreInfo(productDescription),
    },
  ]
    .filter((item) => !!item.component)
    .map(({ title, id, analyticsId, component }) => ({
      contentId: id,
      analyticsId,
      title,
      content: component,
    }));
}
