import styles from "./ErrorPage.scss";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { PropTypes } from "prop-types";

export default function ErrorPage({ text }) {
  const { getTranslation } = useRuntimeConstants();
  return (
    <div className={styles.main} aria-live="polite">
      <div className={styles.errorMessage}>
        <img
          alt=""
          className={styles.face}
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAABYCAMAAABGS8AGAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAjVBMVEUAAAD5wgv3wQv7xAvn
tApuVgVcSATFmQn/xwuTcwZbRwSYdgfYqQoYEwEAAACkgAdQPgQbFQFSQATotArdrAowJgIcFgGu
iAhlTwQXEgHhrwrwuwsgGQE4LALuuQvjsQrcqwqyiggeGAGcegdVQgSbeQepgweMbQZpUgW7kgjC
lwmkfwfYqAo9LwO8kwip5RhEAAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAHdElNRQflAhoN
Ng/eQtu9AAABrUlEQVRYw+3WDVOCMBgHcAcKEzEV1zJYaTJfMuv7f7w2QKTdyIeh5tn+J3f6bPfb
QHi007mzoF9i4VuEUeNcyrXwLcAGJoy38MXhdi66hFnPW9jCFr4ufD5S0S1sYQu3g526Abc53O15
ZXysrNMPRDXIxgYNYRwOH8qM1BMYH8eCpjsOJxVY2TGKjmNe4x3/AjvXgTHCQHhKRPzHiQyVx5MK
z54nRYazmJCYJCC4RxljL35Y5lWdMS+HFn0m84YBcH6iSyLnisN1NTcrLl+p9r7Rw1xOZURz5TQr
rDJ4DdpxDi9g8AAO53cpJRD3AI9A8GYc8WjbhcHvEecR34Fgx6nvOtrJImr5r9vmf4FP/4MTfT3w
qvExyDwFV/t6no8zwVyFR7cN4+ov0QHOWp9jDiey5xNvKBo+zdu+eENpGi8ImSfIGMZr2fNZSsJq
9vtAlJefc9ccXh3OXPb0ovOLD0XHbrHjVPtdJdlFZ3F7WK0XOz7DpfhZzp8Y2gJGX1xmp1SdzVhU
t1MX4NbymiYuq9p6pz6oVSwMgY31U6YpDHItfAUYukhzzcL3AZvnG8RuNnP7B0HGAAAAJXRFWHRk
YXRlOmNyZWF0ZQAyMDIxLTAyLTI2VDEzOjU0OjEwKzAwOjAwfH58/gAAACV0RVh0ZGF0ZTptb2Rp
ZnkAMjAyMS0wMi0yNlQxMzo1NDoxMCswMDowMA0jxEIAAAAASUVORK5CYII="
        />

        <div>
          <h1 className={styles.heading}>
            {getTranslation("pdp_misc_error_oops")}
          </h1>
          <p className={styles.paragraph} id="errorMessage">
            {text || getTranslation("pdp_misc_error_technical_difficulties")}
          </p>
        </div>
      </div>
    </div>
  );
}

ErrorPage.propTypes = {
  text: PropTypes.string,
};
