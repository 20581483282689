export const replaceTokensWithBoldStyling = (text, replacements, styling) =>
  Object.keys(replacements).reduce((accumulator, currentKey) => {
    const nextString = accumulator.replace(
      new RegExp(`{${currentKey}}`, "g"),
      `<strong ${styling ? `class=${styling}` : ""}>${
        replacements[currentKey]
      }</strong>`
    );
    return nextString;
  }, text);

export const replaceTokens = (text, replacements) =>
  Object.keys(replacements).reduce((accumulator, currentKey) => {
    const nextString = accumulator.replace(
      new RegExp(`{${currentKey}}`, "g"),
      replacements[currentKey]
    );
    return nextString;
  }, text);
