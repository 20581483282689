import { useStockPrice } from "../../context/stockPrice/StockPriceContext";
import styles from "./SkeletonPrimaryActions.scss";

export default function SkeletonPrimaryActions() {
  const { products, shouldRetry } = useStockPrice();

  if (products || shouldRetry) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.variantSelectorSkeleton} />
      <div className={styles.actionsSkeleton}>
        <div className={styles.addToBagSkeleton} />
        <div className={styles.saveForLaterSkeleton} />
      </div>
    </div>
  );
}
