import { forwardRef } from "react";
import styles from "./SideScrollCarousel.scss";
import PropTypes from "prop-types";

const SideScrollCarousel = forwardRef(({ children, testId }, ref) => (
  <ul ref={ref} className={styles.carousel} data-testid={testId}>
    {children}
  </ul>
));

SideScrollCarousel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  testId: PropTypes.string,
};

SideScrollCarousel.displayName = "SideScrollCarousel";

export default SideScrollCarousel;
