import styles from "./ProductDescription.scss";
import PropTypes from "prop-types";

export function MoreInfo({ moreInfoVisible, badges }) {
  if (!badges?.length || !moreInfoVisible) {
    return null;
  }

  return (
    <ul className={styles.badges}>
      {badges.map(({ contentUrl, imageUrl, tooltip }) => (
        <li key={contentUrl}>
          <a href={contentUrl} target="_blank" rel="noreferrer">
            <img src={imageUrl} alt={tooltip} title={tooltip} />
          </a>
        </li>
      ))}
    </ul>
  );
}

MoreInfo.propTypes = {
  moreInfoVisible: PropTypes.bool,
  badges: PropTypes.arrayOf(
    PropTypes.shape({
      contentUrl: PropTypes.string.isRequired,
      tooltip: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
    })
  ),
};
