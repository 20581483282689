import { useEffect, useState } from "react";
import classnamesBind from "classnames/bind";

import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { getFeature } from "../../utils/features";
import { useFeaturesReady } from "../../context/featuresReady/FeaturesReadyContext";
import RatingBar from "../ratingBar/RatingBar";
import { getSubRatingIcon } from "../../utils/getSubRatingIcon";

import styles from "./CustomerFitRating.scss";

const classnames = classnamesBind.bind(styles);

const CustomerFitRating = () => {
  const [subRatingFitInfo, setSubRatingFitInfo] = useState(null);
  const { areFeaturesReady } = useFeaturesReady();
  const { ratings, getTranslation } = useRuntimeConstants();

  useEffect(() => {
    if (!isSubratedProduct(ratings)) {
      return;
    }

    if (areFeaturesReady && getFeature("pdp-sizing-slider").isEnabled()) {
      setSubRatingFitInfo(ratings?.subRatings?.find(subRatingMatcher));
    }
  }, [areFeaturesReady, ratings, ratings?.subRatings]);

  const heading =
    subRatingFitInfo &&
    translateTitleForSubRatingId(getTranslation, subRatingFitInfo);

  return subRatingFitInfo && heading ? (
    <div className={styles.fitInfoContainer}>
      <div className={styles.fitInfoLabel}>
        <div className={classnames(styles.icon, getSubRatingIcon("Fit"))} />
        <h2>{heading}</h2>
      </div>
      <div className={styles.fitInfoBar}>
        <RatingBar
          label={subRatingFitInfo.label}
          id={subRatingFitInfo.id}
          minLabel={getTranslation("pdp_ratings_reviews_slider_size_min_label")}
          maxLabel={getTranslation("pdp_ratings_reviews_slider_size_max_label")}
          percentage={subRatingFitInfo.percentage}
        />
      </div>
    </div>
  ) : null;
};

function isSubratedProduct(ratings) {
  return ratings?.subRatings?.some(subRatingMatcher);
}

function subRatingMatcher(subRating) {
  return subRating.id === "Size" || subRating.id === "Fit";
}

function translateTitleForSubRatingId(getTranslation, subRating = {}) {
  const titleMap = {
    Fit: "pdp_ratings_reviews_slider_fitmessage",
    Size: "pdp_ratings_reviews_slider_sizemessage",
  };
  return getTranslation(titleMap[subRating.id]);
}

export default CustomerFitRating;
