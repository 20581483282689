import { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

const ProductContext = createContext(null);

export const ProductContextProvider = ({ products, productId, children }) => {
  const [product, setProduct] = useState(null);

  useEffect(() => {
    if (products?.length && productId) {
      const productForContext = products.find(({ id }) => id === productId);
      setProduct(productForContext || null);
    }
  }, [products, productId]);

  return (
    <ProductContext.Provider value={{ product }}>
      {children}
    </ProductContext.Provider>
  );
};

ProductContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  products: PropTypes.array,
  productId: PropTypes.number,
};

export const useProduct = () => useContext(ProductContext);
