import styles from "./ProductDescription.scss";
import PropTypes from "prop-types";

export function GenericAccordionItem({ isVisible, content }) {
  if (!isVisible) {
    return null;
  }

  return (
    <div
      className={styles.content}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
}

GenericAccordionItem.propTypes = {
  isVisible: PropTypes.bool,
  content: PropTypes.string,
};
