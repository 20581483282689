import { useEffect, useState } from "react";
import PropTypes from "prop-types";

export default function ClientOnly({ children }) {
  const [isClientPostRender, setIsClientPostRender] = useState(false);

  useEffect(() => {
    setIsClientPostRender(true);
  }, []);

  return isClientPostRender ? <>{children}</> : null;
}

ClientOnly.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
