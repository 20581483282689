import { useStockPrice } from "../../context/stockPrice/StockPriceContext";
import styles from "./StockPriceRetry.scss";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";

export default function StockPriceRetry() {
  const { getTranslation } = useRuntimeConstants();
  const { shouldRetry, retryStockPriceCall } = useStockPrice();

  if (!shouldRetry) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div>
        <div className="product-icon-exclamation" />
      </div>
      <div className={styles.textContainer}>
        <h2>{getTranslation("pdp_status_error_stock_price_retry_title")}</h2>
        <p>{getTranslation("pdp_status_error_stock_price_retry_message")}</p>
        <button
          onClick={() => retryStockPriceCall(true)}
          data-testid="stock-price-retry-button"
        >
          {getTranslation("pdp_status_error_stock_price_retry_button")}
        </button>
      </div>
    </div>
  );
}
