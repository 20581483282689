import getRuntimeConstants from "../runtimeConstants";
import makeRequest from "./makeRequest";
import { POST } from "../constants";
import { getAuthorisationHeader } from "../utils/identity";

async function fetchPromoMessages(url, body, params) {
  const Authorization = await getAuthorisationHeader();

  return makeRequest({
    url,
    body,
    params,
    method: POST,
    apiName: "Promotion Messages",
    caller: "Promotion Messages",
    headers: {
      "Content-Type": "application/json",
      Authorization,
    },
  });
}

function getSellers(variants) {
  const sellers = new Set();
  variants.forEach((variant) => {
    if (typeof variant.seller === "object") {
      sellers.add(JSON.stringify(variant.seller));
    }
  });
  return Array.from(sellers).map((seller) => JSON.parse(seller));
}

function getProductsObj(product) {
  const sellers = getSellers(product.variants);
  const plpIds = product.plpIds.map((plpId) => plpId.id);

  return {
    productId: product.id,
    isMarkedDown: product.price?.isMarkedDown,
    isOutletPrice: product.price?.isOutletPrice,
    plpIds,
    sellers,
  };
}

export default function getPromoMessaging(product) {
  const {
    webContext: {
      browseCurrency,
      browseLanguage,
      browseCountry,
      storeId,
      keyStoreDataversion,
    },
    promotionsMessagingApi,
  } = getRuntimeConstants();

  const body = {
    store: storeId,
    currency: browseCurrency,
    lang: browseLanguage,
    country: browseCountry,
    products: [getProductsObj(product)],
  };

  return fetchPromoMessages(promotionsMessagingApi, body, {
    keyStoreDataversion,
  });
}
