import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import styles from "./ProductCode.scss";
import { useIsInStockContext } from "../../context/isInStock/IsInStockContext";

export default function ProductCode() {
  const { getTranslation, product } = useRuntimeConstants();
  const { isInStock } = useIsInStockContext();

  if (isInStock !== false) {
    return null;
  }

  return (
    <div className={styles.productCode}>
      <span className={styles.label}>
        {getTranslation("pdp_description_product_code_title")}
      </span>
      <span>&nbsp;</span>
      <span>{product.productCode}</span>
    </div>
  );
}
