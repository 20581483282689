import { Component } from "react";
import PropTypes from "prop-types";
import { logError, ERROR_TYPE } from "../../utils/newRelicLogger";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.fallBackUI = props.fallBackUI ? props.fallBackUI : <></>;
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch({ message }) {
    logError(ERROR_TYPE.NonCriticalAppError, {
      message,
    });
  }

  render() {
    return this.state.hasError ? this.fallBackUI : this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  fallBackUI: PropTypes.node,
};

export default ErrorBoundary;
