import { useState, useRef, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./IngredientsModal.scss";
import Modal from "../modal/Modal";
import ErrorPage from "../errorPage/ErrorPage";
import { MODAL_TYPE } from "../../constants";
import { connect } from "@asos-web-messaging-bridge/bridge-web";
import { logError, ERROR_TYPE } from "../../utils/newRelicLogger";
import classnamesBind from "classnames/bind";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { useStockPrice } from "../../context/stockPrice/StockPriceContext";
import { useVariant } from "../../context/variant/VariantContext";

const classnames = classnamesBind.bind(styles);

const IngredientsModal = ({ open, focusOnClose, close }) => {
  const { ingredientsPageUrl, ingredientsModalTimeout, webContext } =
    useRuntimeConstants();
  const { products } = useStockPrice();

  const [connectedToFrame, setConnectedToFrame] = useState(false);
  const [showError, setShowError] = useState(false);
  const { selectedVariant } = useVariant();
  const bridgeConnection = useRef(null);
  const timeoutRef = useRef(null);

  const requestUrl = `${ingredientsPageUrl}?lang=${webContext.browseLanguage}`;
  const modalId = "ingredientsModal";

  const colourVariantMapper = useCallback(() => {
    const inStockVariantsWithIngredients = [];

    products?.variants?.forEach((variant) => {
      if (variant.isInStock && variant.hasIngredients) {
        const { ean, sku } = variant;
        const isSelected = variant.colour === selectedVariant?.colour;

        inStockVariantsWithIngredients.push({
          id: ean || sku,
          type: ean ? "ean" : "sku",
          name: variant.colour,
          isSelected,
        });
      }
    });
    return inStockVariantsWithIngredients;
  }, [products, selectedVariant]);

  useEffect(() => {
    return () => {
      disconnect();
    };
  }, []);

  const disconnect = () => {
    clearTimeout(timeoutRef.current);
    bridgeConnection.current?.disconnect();
  };

  const closeModal = () => {
    setShowError(false);
    setConnectedToFrame(false);
    disconnect();
    close();
  };

  const afterRender = useCallback(
    (iframeElement) => {
      let bridgeWebConnection;

      if (open && iframeElement) {
        new Promise((resolve, reject) => {
          timeoutRef.current = setTimeout(() => {
            reject(
              `Skin Match page request timed out after ${ingredientsModalTimeout} ms`
            );
          }, ingredientsModalTimeout);

          connect(iframeElement, requestUrl, (message, connection) => {
            clearTimeout(timeoutRef.current);
            resolve(connection);
          });
        })
          .then((connection) => {
            bridgeConnection.current = connection;
            bridgeWebConnection = connection;
            return connection;
          })
          .then(async (iFrameConnection) => {
            const availableColours = await colourVariantMapper();

            if (!iFrameConnection) {
              return null;
            }

            setConnectedToFrame(true);
            iFrameConnection.send(availableColours);
          })
          .catch((error) => {
            setShowError(true);

            logError(ERROR_TYPE.UserActionFailed, {
              caller: "IngredientsModal",
              action: "IngredientsModalTimeout",
              requestUrl: requestUrl,
              message: error,
            });
          });
      }

      if (!iframeElement) {
        clearTimeout(timeoutRef.current);
        bridgeWebConnection?.disconnect();
      }
    },
    [open, requestUrl, ingredientsModalTimeout]
  );

  return (
    <Modal
      open={open}
      contentLoaded={connectedToFrame || showError}
      modalId={modalId}
      focusOnClose={focusOnClose}
      closeModal={closeModal}
      modalType={MODAL_TYPE.SIDE}
      withScrollBar={false}
      forceDOMRewrite={true}
      useFocusTrapCatchAll={true}
      withDarkThemeSupport={!showError}
    >
      {showError ? (
        <ErrorPage />
      ) : (
        <iframe
          className={classnames(styles.frame, {
            hidden: !connectedToFrame,
          })}
          title="Ingredients content"
          ref={afterRender}
          aria-hidden={!connectedToFrame}
        ></iframe>
      )}
    </Modal>
  );
};

export default IngredientsModal;

IngredientsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  focusOnClose: PropTypes.node,
  close: PropTypes.func.isRequired,
};
