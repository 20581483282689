import styles from "./LiveRegion.scss";
import { useLiveRegion } from "../../context/liveRegion/LiveRegionContext";

export default function LiveRegion() {
  const { liveRegionText } = useLiveRegion();

  return (
    <div
      aria-live="polite"
      role="status"
      className={styles.liveRegion}
      data-testid="ariaLiveRegion"
    >
      {liveRegionText}
    </div>
  );
}
