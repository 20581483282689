import { StarRatingButton, StarRatingGroup } from "../index";
import { RATINGS_WITHOUT_REVIEWS_TEXT } from "../ratingTypes";
import useRatingsFeatureRequirements from "../../../hooks/ratingsFeatureRequirements/useRatingsFeatureRequirements";
import { useIsInStockContext } from "../../../context/isInStock/IsInStockContext";
import { useRuntimeConstants } from "../../../context/runtimeConstants/RuntimeConstantsContext";

export default function RatingComponent() {
  const { webContext, ratings } = useRuntimeConstants();
  const { isInStock } = useIsInStockContext();
  const ratingsFeatureRequirementsAchieved = useRatingsFeatureRequirements(
    ratings,
    isInStock
  );

  if (!ratingsFeatureRequirementsAchieved) {
    return null;
  }

  const {
    averageOverallStarRating,
    averageOverallRating,
    totalReviewCount,
    displayRatingsSection,
  } = ratings;

  const ratingsProps = {
    averageOverallStarRating,
    averageOverallRating,
    totalReviewCount,
    language: webContext.browseLanguage,
  };

  return (
    ratingsFeatureRequirementsAchieved && (
      <>
        {displayRatingsSection ? (
          <StarRatingButton {...ratingsProps} />
        ) : (
          <StarRatingGroup
            {...ratingsProps}
            type={RATINGS_WITHOUT_REVIEWS_TEXT}
          />
        )}
      </>
    )
  );
}
