import PropTypes from "prop-types";

import { useIsInStockContext } from "../../context/isInStock/IsInStockContext";

const IfInStock = ({ children }) => {
  const { isStockStateReady, isInStock } = useIsInStockContext();

  return isStockStateReady && isInStock === true && <>{children}</>;
};

IfInStock.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default IfInStock;
