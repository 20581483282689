import { PrimaryPrice } from "../priceComponents";
import PaymentPromo from "../paymentPromo/PaymentPromo";
import useVariantPrice from "./useVariantPrice";
import { useVariant } from "../../context/variant/VariantContext";
import styles from "./PriceData.scss";
import {
  setDiscountOnPrice,
  setPricingIconLoad,
} from "../../analytics/pageLoad";
import { useStockPrice } from "../../context/stockPrice/StockPriceContext";
import setComponentPerformance from "../../utils/setComponentPerformance";
import { firePricingModalOpen } from "../../analytics/pageInteraction";

export default function PriceData() {
  const { products, shouldRetry } = useStockPrice();
  const { selectedVariantId } = useVariant();

  const { priceData, showFromLabel } = useVariantPrice({
    product: products,
    selectedVariantId,
  });

  if (shouldRetry) {
    return null;
  }

  if (!priceData) {
    return <div className={styles.skeleton} />;
  }

  setComponentPerformance({ metric: "pdp:price_displayed" });
  setDiscountOnPrice({ priceData });

  return (
    <>
      <PrimaryPrice
        priceData={priceData}
        showFromLabel={showFromLabel}
        testId="product-price"
        firePricingModalOpen={firePricingModalOpen}
        setPricingIconLoad={setPricingIconLoad}
      />
      {products?.isInStock && (
        <PaymentPromo priceValue={priceData.current?.value} />
      )}
    </>
  );
}
