import React from "react";
import styles from "./RestockingSoonBanner.scss";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { useProduct } from "../../context/product/ProductContext";

const RestockingSoonBanner = () => {
  const { getTranslation } = useRuntimeConstants();
  const { product } = useProduct();

  if (!product) {
    return null;
  }

  const { shouldShowRestockingSoon, restockingLeadTimeWeeks } =
    product.restockingSoonDetails;

  if (!shouldShowRestockingSoon) {
    return null;
  }

  return (
    <div className={styles.container}>
      <p className={styles.title}>
        {getTranslation("back_in_stock_soon_message_title")}
      </p>
      <p className={styles.bodyText}>
        {getTranslation("back_in_stock_soon_message_description", {
          smart_count: restockingLeadTimeWeeks,
          restockingLeadTimeWeeks,
        })}
      </p>
    </div>
  );
};

export default RestockingSoonBanner;
