import styles from "./ReportProductLink.scss";
import classnamesBind from "classnames/bind";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { REPORT_LEGAL_CONCERN_LINK_PATH } from "../../constants";

const classnames = classnamesBind.bind(styles);

const buildLink = ({ product }) => {
  const queryParams = new URLSearchParams({
    productCode: product?.productCode,
    productTitle: product?.name,
  });

  return `${REPORT_LEGAL_CONCERN_LINK_PATH}?${queryParams}`;
};

const ReportProductLink = () => {
  const {
    getTranslation,
    product,
    digitalServicesAct: { isDsaEnabledCountry },
  } = useRuntimeConstants();

  if (!isDsaEnabledCountry) {
    return null;
  }

  return (
    <div className={styles.container} data-testid="report-concern-link">
      <a
        className={styles.link}
        href={buildLink({ product })}
        target="_blank"
        aria-label={`${getTranslation(
          "pdp_digital_services_act_link"
        )}. ${getTranslation("generic_opens_new_window")}`}
        rel="noreferrer"
      >
        {getTranslation("pdp_digital_services_act_link")}
        <span
          data-testid="newWindowIcon"
          className={classnames(styles.icon, "product-new-page-small")}
        />
      </a>
    </div>
  );
};

export default ReportProductLink;
