import styles from "./ProductDescription.scss";
import PropTypes from "prop-types";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import SizeGuideButton, {
  SIZE_GUIDE_BUTTON_SIZE,
} from "../sizeGuideButton/SizeGuideButton";
import { NEW_USER } from "../../analytics/fitAssistantCommon";
import { useSizing } from "../../context/sizing/SizingContext";

const getSizingTranslation = ({
  getTranslation,
  recommendedSize,
  isPartialRecommendation,
}) => {
  if (recommendedSize === NEW_USER) {
    return getTranslation("pdp_fit_analytics_sizing_help_profile_none");
  }
  if (recommendedSize !== NEW_USER && isPartialRecommendation) {
    return getTranslation("pdp_fit_analytics_sizing_help_profile_partial");
  }
  return getTranslation("pdp_fit_analytics_sizing_help_profile_complete");
};

const handleKeyPress = (handleFitAssistantLinkClick) => (event) => {
  if (event.key === "Enter") {
    handleFitAssistantLinkClick(event);
  }
};

const getSizingHelpText = ({
  getTranslation,
  recommendedSize,
  sizeGuideUrl,
  isPartialRecommendation,
  handleFitAssistantLinkClick,
}) => {
  const introText = getTranslation("pdp_fit_analytics_sizing_help_intro");
  const sizingTranslation = getSizingTranslation({
    getTranslation,
    recommendedSize,
    isPartialRecommendation,
  });

  const [fitAssistantLinkText, sizeGuideText] =
    sizingTranslation.split("%{splitPoint}");

  const [sizeGuideIntroText, sizeGuideButtonText] =
    sizeGuideText.split("%{link}");

  return (
    <p>
      {introText}
      {fitAssistantLinkText && (
        <span
          role="button"
          tabIndex={0}
          data-testid="saf-fit-assistant"
          onClick={handleFitAssistantLinkClick}
          onKeyDown={handleKeyPress(handleFitAssistantLinkClick)}
          className={styles.link}
        >
          {" "}
          {fitAssistantLinkText}
        </span>
      )}
      {sizeGuideUrl && (
        <>
          {" "}
          {sizeGuideIntroText}
          <SizeGuideButton
            sizeGuideUrl={sizeGuideUrl}
            text={sizeGuideButtonText.replace("%{linkEnd}", "")}
            size={SIZE_GUIDE_BUTTON_SIZE.LARGE}
          />
        </>
      )}
    </p>
  );
};

const checkRecommendations = ({ recommendedSize, isPartialRecommendation }) =>
  recommendedSize === NEW_USER ||
  (recommendedSize !== NEW_USER && isPartialRecommendation);

const showSizingHelp = ({
  sizeGuideUrl,
  recommendedSize,
  isPartialRecommendation,
}) =>
  sizeGuideUrl ||
  checkRecommendations({
    recommendedSize,
    isPartialRecommendation,
  });

const showSizeAndFit = ({
  sizeAndFitVisible,
  sizeGuideUrl,
  recommendedSize,
  isPartialRecommendation,
}) =>
  sizeAndFitVisible ||
  (sizeGuideUrl && recommendedSize !== null) ||
  checkRecommendations({
    recommendedSize,
    isPartialRecommendation,
  });

export const SizeAndFit = ({ sizeAndFitVisible, sizeAndFit }) => {
  const { getTranslation } = useRuntimeConstants();
  const {
    sizeGuideUrl,
    recommendedSize,
    isPartialRecommendation,
    handleFitAssistantLinkClick,
  } = useSizing();

  if (
    !showSizeAndFit({
      sizeAndFitVisible,
      sizeGuideUrl,
      recommendedSize,
      isPartialRecommendation,
    })
  ) {
    return null;
  }

  return (
    <>
      {sizeAndFitVisible && (
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: sizeAndFit }}
        />
      )}
      {showSizingHelp({
        sizeGuideUrl,
        recommendedSize,
        isPartialRecommendation,
      }) && (
        <div
          className={styles.content}
          data-testid="product-description-sizing-help"
        >
          <h3>{getTranslation("pdp_fit_analytics_sizing_help_title")}</h3>
          {getSizingHelpText({
            getTranslation,
            recommendedSize,
            sizeGuideUrl,
            isPartialRecommendation,
            handleFitAssistantLinkClick,
          })}
        </div>
      )}
    </>
  );
};

SizeAndFit.propTypes = {
  sizeAndFitVisible: PropTypes.bool,
  sizeAndFit: PropTypes.string,
};
