import { useMemo } from "react";

export default function useVariantPrice({ selectedVariantId, product }) {
  return useMemo(() => {
    if (!product?.variants?.length) {
      return {
        showFromLabel: false,
        priceData: null,
      };
    }

    if (selectedVariantId) {
      const selectedVariantPrice = getPriceForSelectedVariant({
        selectedVariantId,
        variants: product.variants,
      });

      if (selectedVariantPrice) {
        return {
          showFromLabel: false,
          priceData: selectedVariantPrice,
        };
      }
    }

    const inStockVariants = getInStockVariants(product.variants);

    if (!inStockVariants.length) {
      return {
        showFromLabel: false,
        priceData: product.price,
      };
    }

    const lowestPriceInStockVariants = getLowestPriceVariants(inStockVariants);

    return {
      showFromLabel:
        lowestPriceInStockVariants.length !== inStockVariants.length,
      priceData: getPriceForNoSelection(lowestPriceInStockVariants),
    };
  }, [selectedVariantId, product]);
}

function getInStockVariants(variants) {
  return variants.filter(({ isInStock }) => isInStock);
}

function getLowestPriceVariants(variants) {
  return variants.reduce(
    (lowestCurrentPriceVariants, variant, index) => {
      if (index === 0) {
        return lowestCurrentPriceVariants;
      }

      const lowestPrice = lowestCurrentPriceVariants[0].price.current.value;
      const currentVariantPrice = variant.price.current.value;

      if (currentVariantPrice < lowestPrice) {
        return [variant];
      }

      if (currentVariantPrice === lowestPrice) {
        lowestCurrentPriceVariants.push(variant);
      }
      return lowestCurrentPriceVariants;
    },
    [variants[0]]
  );
}

function getPriceForSelectedVariant({ selectedVariantId, variants }) {
  return variants.find((variant) => variant.variantId === selectedVariantId)
    ?.price;
}

function priceIsNotMarkedDownOrOutlet({ isMarkedDown, isOutletPrice }) {
  return !isMarkedDown && !isOutletPrice;
}

function getPriceForNoSelection(lowestPriceVariantsInStock) {
  const doesNextVariantPriceHaveMoreExpensivePrice = (
    currentVariantPrice,
    nextVariantPrice,
    priceProp
  ) => {
    const currentPrice = currentVariantPrice[priceProp]?.value;
    const nextPrice = nextVariantPrice[priceProp].value;

    return typeof currentPrice !== "number" || nextPrice > currentPrice;
  };

  const doesNextVariantPriceHaveMoreExpensiveRrp = (
    currentVariantPrice,
    nextVariantPrice
  ) => {
    if (!currentVariantPrice.isOutletPrice && nextVariantPrice.isOutletPrice) {
      return true;
    }

    return !!(
      nextVariantPrice.isOutletPrice &&
      doesNextVariantPriceHaveMoreExpensivePrice(
        currentVariantPrice,
        nextVariantPrice,
        "rrp"
      )
    );
  };

  const doesNextVariantPriceHaveMoreExpensivePreviousPrice = (
    currentVariantPrice,
    nextVariantPrice
  ) => {
    if (
      priceIsNotMarkedDownOrOutlet(currentVariantPrice) &&
      nextVariantPrice.isMarkedDown
    ) {
      return true;
    }

    return !!(
      nextVariantPrice.isMarkedDown &&
      !currentVariantPrice.isOutletPrice &&
      doesNextVariantPriceHaveMoreExpensivePrice(
        currentVariantPrice,
        nextVariantPrice,
        "previous"
      )
    );
  };

  return lowestPriceVariantsInStock.reduce(
    (currentVariantPrice, { price: nextVariantPrice }) =>
      !currentVariantPrice ||
      doesNextVariantPriceHaveMoreExpensiveRrp(
        currentVariantPrice,
        nextVariantPrice
      ) ||
      doesNextVariantPriceHaveMoreExpensivePreviousPrice(
        currentVariantPrice,
        nextVariantPrice
      )
        ? nextVariantPrice
        : currentVariantPrice,

    null
  );
}
