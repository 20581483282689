import styles from "./FacetPalette.scss";
import FacetThumbnail from "../facetThumbnail/FacetThumbnail";
import { PropTypes } from "prop-types";
import ProductColour from "../../productColour/ProductColour";
import { useRuntimeConstants } from "../../../context/runtimeConstants/RuntimeConstantsContext";

const FacetPalette = ({ facet, handleColourLabel, colourLabel }) => {
  const { getTranslation } = useRuntimeConstants();

  return (
    <div>
      <ProductColour colourLabel={colourLabel} />
      <nav
        aria-label={getTranslation(
          "pdp_grouping_accessibility_colour_palette_description"
        )}
      >
        <ul className={styles.facetPaletteList} data-testid="facetList">
          {facet.products.map((productFacet) => (
            <FacetThumbnail
              productFacet={productFacet}
              key={productFacet.productId}
              onFocus={handleColourLabel}
            />
          ))}
        </ul>
      </nav>
    </div>
  );
};

FacetPalette.propTypes = {
  facet: PropTypes.shape({
    facetGroupId: PropTypes.string,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        productId: PropTypes.number,
        description: PropTypes.string,
        imageUrl: PropTypes.string,
        isSelected: PropTypes.bool,
        isInStock: PropTypes.bool,
        brandName: PropTypes.string,
      }).isRequired
    ).isRequired,
  }).isRequired,
  handleColourLabel: PropTypes.func.isRequired,
  colourLabel: PropTypes.string.isRequired,
};

export default FacetPalette;
