import { LEGISLATION_API_TIMEOUT } from "../constants";
import makeRequest from "./makeRequest";

export default async function getVariantSustainabilityData(
  colourWayId,
  browseLanguage
) {
  return makeRequest({
    url: `/assets/legislation/${colourWayId}.${browseLanguage}.json`,
    timeout: LEGISLATION_API_TIMEOUT,
    mapData: (response) => response.legislationData,
    handleErrorReturnValue: () => {
      throw new Error("Error getting sustainability data");
    },
  });
}
