import styles from "./ProductDescription.scss";
import PropTypes from "prop-types";
import SustainabilityInfoContainer from "../sustainabilityInfo/SustainabilityInfoContainer";

export function AboutMe({ aboutMeVisible, aboutMe }) {
  const sustainabilityInfo = SustainabilityInfoContainer();

  if (!aboutMeVisible && !sustainabilityInfo) {
    return null;
  }

  return (
    <>
      {aboutMeVisible && (
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: aboutMe }}
        />
      )}
      {sustainabilityInfo}
    </>
  );
}

AboutMe.propTypes = {
  aboutMeVisible: PropTypes.bool,
  aboutMe: PropTypes.string,
};
