import { useState } from "react";
import IngredientsModal from "../../components/ingredientsModal/IngredientsModal";
import IngredientsButton from "../../components/ingredientsButton/IngredientsButton";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { useIsInStockContext } from "../../context/isInStock/IsInStockContext";

export default function IngredientsContainer() {
  const { product } = useRuntimeConstants();
  const [openIngredients, setOpenIngredients] = useState(false);
  const { isInStock } = useIsInStockContext();
  const [ingredientsButtonFocusTarget, setIngredientsButtonFocusTarget] =
    useState(null);

  const renderIngredientsButton = () =>
    product.hasVariantsWithIngredients &&
    isInStock && (
      <IngredientsButton
        onClick={(event) => {
          setOpenIngredients(true);
          setIngredientsButtonFocusTarget(event.target);
        }}
      />
    );

  return (
    <>
      {renderIngredientsButton()}
      <IngredientsModal
        open={openIngredients}
        focusOnClose={ingredientsButtonFocusTarget}
        close={() => {
          setOpenIngredients(false);
        }}
      />
    </>
  );
}
