import { getCommons, getGlobalEvars, getAsosFulfilsPrefix } from "./common";
import { waitForPageLoadAnalytics } from "./pageLoad";
import getWindow from "../utils/window";
import getRuntimeConstants from "../runtimeConstants";
import { clearEvents, setEvents, setLinkTrackEvents } from "./events";

const alreadyTrackedVariants = new Set();

function hasSourceOrSeller({ source, seller }) {
  return source || seller;
}

function getSourceId({ source }) {
  return source ? source.id.toLowerCase() : "primary";
}

function getSellerId({ seller }) {
  return seller ? seller.id.toLowerCase() : "asos";
}

function shouldNotFireTracking({
  source,
  seller,
  isMixAndMatch,
  selectedVariant,
}) {
  return (
    (!hasSourceOrSeller({ source, seller }) && isMixAndMatch) ||
    alreadyTrackedVariants.has(selectedVariant.variantId)
  );
}

export default async function fireSourcingLocationAnalytics({
  selectedVariant,
}) {
  await waitForPageLoadAnalytics;
  const { isMixAndMatch } = getRuntimeConstants();
  const { seller, source } = selectedVariant;

  if (
    shouldNotFireTracking({ source, seller, isMixAndMatch, selectedVariant })
  ) {
    return;
  }

  const commons = getCommons();
  const s = getWindow().s;

  alreadyTrackedVariants.add(selectedVariant.variantId);

  const sourceId = getSourceId({ source });
  const sellerId = getSellerId({ seller });

  clearEvents({ trackingName: "sourcingLocation" });
  setEvents({
    events: [hasSourceOrSeller({ source, seller }) ? "event288" : "event303"],
    trackingName: "sourcingLocation",
  });

  if (!selectedVariant.isInStock) {
    setEvents({
      events: ["event225"],
      trackingName: "sourcingLocation",
    });
  }

  const variantAndPrefix = `${selectedVariant.variantId}:${getAsosFulfilsPrefix(
    {
      sellerId,
      sourceId,
    }
  )}`;

  s.eVar210 = `${variantAndPrefix}${sourceId}`;
  s.eVar214 = `${variantAndPrefix}${sellerId}`;

  s.tl(true, "o", "sourcing location messaging Impression", {
    linkTrackEvents: setLinkTrackEvents({ trackingName: "sourcingLocation" }),
    linkTrackVars: commons.setLinkTrackVars([
      ...getGlobalEvars(),
      "eVar210",
      "eVar212",
      "eVar214",
    ]),
  });
}
