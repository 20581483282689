import { useRef, useLayoutEffect } from "react";
import styles from "./FacetCarousel.scss";
import FacetThumbnail from "../facetThumbnail/FacetThumbnail";
import { useRuntimeConstants } from "../../../context/runtimeConstants/RuntimeConstantsContext";
import { PropTypes } from "prop-types";
import ProductColour from "../../productColour/ProductColour";
import { PRODUCT_COLOUR_TYPES } from "../../../constants";
import SideScrollCarousel from "../../carousel/sideScrollCarousel/SideScrollCarousel";

const FacetCarousel = ({ facet, colourLabel }) => {
  const { getTranslation } = useRuntimeConstants();
  const carouselRef = useRef();

  useLayoutEffect(() => {
    if (!carouselRef.current) return;
    const targetIndex = facet.products.findIndex(
      (facetProduct) => facetProduct.isSelected
    );

    const targetPosition =
      carouselRef.current.children[targetIndex].getBoundingClientRect();

    const offset = (targetPosition.right - targetPosition.left) / 2;

    carouselRef.current.scrollLeft = targetPosition.left - offset;
  }, [facet.products]);

  return (
    <div className={styles.facetCarouselContainer} key={facet.facetGroupId}>
      <ProductColour
        colourLabel={colourLabel}
        type={PRODUCT_COLOUR_TYPES.WITHIN_CAROUSEL_FACETS}
      />
      <nav
        aria-label={getTranslation(
          "pdp_grouping_accessibility_colour_palette_description"
        )}
        className={styles.navigation}
      >
        <SideScrollCarousel ref={carouselRef} testId="facetList">
          {facet.products.map((productFacet) => (
            <FacetThumbnail
              productFacet={productFacet}
              isCarousel
              key={productFacet.productId}
            />
          ))}
        </SideScrollCarousel>
      </nav>
    </div>
  );
};

FacetCarousel.propTypes = {
  facet: PropTypes.shape({
    facetGroupId: PropTypes.string,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        productId: PropTypes.number,
        description: PropTypes.string,
        imageUrl: PropTypes.string,
        isSelected: PropTypes.bool,
        isInStock: PropTypes.bool,
        brandName: PropTypes.string,
      }).isRequired
    ).isRequired,
  }).isRequired,
  colourLabel: PropTypes.string.isRequired,
};

export default FacetCarousel;
