import { useState, useEffect } from "react";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { useViewport } from "../../context/viewport/ViewportContext";
import FacetCarousel from "../facets/facetCarousel/FacetCarousel";
import FacetPalette from "../facets/facetPalette/FacetPalette";
import ProductColour from "../productColour/ProductColour";
import { getColours, getInStockVariants } from "../../../utils/variantUtils";
import {
  shouldShowFacetCarousel,
  shouldShowFacetPalette,
  shouldShowProductColour,
} from "../facets/utils/shouldShowColour";
import { PropTypes } from "prop-types";
import { getFeature } from "../../utils/features";
import { useFeaturesReady } from "../../context/featuresReady/FeaturesReadyContext";
import { useStockPrice } from "../../context/stockPrice/StockPriceContext";

const getFacetGroupType = (facetGroups, type) =>
  facetGroups ? facetGroups.find((facet) => facet.type === type) : undefined;

const ProductColourContainer = ({ position }) => {
  const [colours, setColours] = useState([]);
  const { product, oosVariantsAreSelectable } = useRuntimeConstants();
  const { products } = useStockPrice();
  const { viewportCategory } = useViewport();
  const [colourLabel, setColourLabel] = useState("");
  const facet = getFacetGroupType(
    product?.facetGroup?.facets,
    "SupplierColour"
  );
  const { areFeaturesReady } = useFeaturesReady();
  const handleFacetColourLabel = ({ productFacet, isFocused }) =>
    setColourLabel(isFocused ? productFacet.description : colours[0]);

  useEffect(() => {
    if (!products) {
      return;
    }
    const stockedVariants = getInStockVariants(products.variants);
    const productIsInStock = !!stockedVariants.length;
    const coloursList = getColours(
      productIsInStock && !oosVariantsAreSelectable
        ? stockedVariants
        : product?.variants
    );

    setColours(coloursList);
    setColourLabel(coloursList[0]);
  }, [products, product, oosVariantsAreSelectable]);

  if (
    shouldShowFacetCarousel({
      viewportCategory,
      product,
      position,
      areFeaturesReady,
      getFeature,
    })
  ) {
    return <FacetCarousel facet={facet} colourLabel={colourLabel} />;
  }

  if (
    shouldShowFacetPalette({
      viewportCategory,
      product,
      position,
      areFeaturesReady,
      getFeature,
    })
  ) {
    return (
      <FacetPalette
        facet={facet}
        handleColourLabel={handleFacetColourLabel}
        colourLabel={colourLabel}
      />
    );
  }

  if (
    shouldShowProductColour({
      product,
      colours,
      position,
      areFeaturesReady,
      getFeature,
    })
  ) {
    return <ProductColour colourLabel={colourLabel} />;
  }

  return null;
};

ProductColourContainer.propTypes = {
  position: PropTypes.string.isRequired,
};

export default ProductColourContainer;
