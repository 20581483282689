import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import styles from "./ProductName.scss";

export default function ProductName() {
  const {
    product: { name: productName },
  } = useRuntimeConstants();

  return (
    <>
      <h1 className={styles.name}>{productName}</h1>
      {
        <script type="text/javascript">
          window.asos.performance.markAndMeasure(`pdp:title_displayed`);
        </script>
      }
    </>
  );
}
