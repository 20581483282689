import { useState, useEffect } from "react";
import { useVariant } from "../../context/variant/VariantContext";
import {
  getInStockVariants,
  doesVariantHaveSeller,
  allVariantsHaveSeller,
} from "../../../utils/variantUtils";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import styles from "./ProductPromo.scss";
import classnamesBind from "classnames/bind";
import getPromoMessaging from "../../api/promotionsMessagingApi";
import { replaceTokensWithBoldStyling } from "../../utils/replaceTokensInString";
import { useProduct } from "../../context/product/ProductContext";
import { PRODUCT_PROMO_LIVE_STORES } from "../../constants";

const classnames = classnamesBind.bind(styles);

const ProductPromo = () => {
  const { webContext } = useRuntimeConstants();
  const { selectedVariantId, selectedVariant } = useVariant();
  const [isSoldByPartner, setIsSoldByPartner] = useState(false);
  const [promoMessages, setPromoMessages] = useState([]);
  const [showNewPromoMessages, setShowNewPromoMessages] = useState(false);
  const { product } = useProduct();

  const getPromoMessage = () => {
    let seller;
    if (allVariantsHaveSeller(product.variants)) {
      seller = product.variants[0].seller;
    } else {
      seller = selectedVariant ? selectedVariant.seller : null;
    }

    return promoMessages?.find((message) => message.seller?.id === seller?.id);
  };

  //This will replace the return method when optimizely is no longer used.
  //Leaving as a small child component here until then
  const PromoBox = () => {
    if (!promoMessages.length || !product) {
      return null;
    }

    const promoMessage = getPromoMessage();

    if (!promoMessage) {
      return null;
    }

    const message = replaceTokensWithBoldStyling(
      promoMessage.displayMessage,
      {
        discountCode: promoMessage.discountCode,
      },
      styles.discountCode
    );

    return (
      <div
        className={styles.promoBox}
        data-testid="promo-message-box"
        aria-live="polite"
      >
        <div className={styles.icon}>
          <div className="product-price-tag" />
        </div>
        <p
          className={styles.promoText}
          aria-hidden
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        />
        <p className={styles.visuallyHidden}>{promoMessage.readerMessage}</p>
      </div>
    );
  };

  useEffect(() => {
    setShowNewPromoMessages(
      PRODUCT_PROMO_LIVE_STORES.includes(webContext?.storeId)
    );
  }, [webContext]);

  useEffect(() => {
    async function storePromoMessages() {
      const result = await getPromoMessaging(product);
      if (!result?.productMessages?.length) {
        setShowNewPromoMessages(false);
      }

      if (result?.productMessages) {
        setPromoMessages(result.productMessages);
      }
    }

    if (showNewPromoMessages && !promoMessages.length && product) {
      storePromoMessages();
    }
  }, [product, promoMessages, showNewPromoMessages]);

  useEffect(() => {
    if (product) {
      const inStockVariants = getInStockVariants(product.variants);
      const doAllVariantsHaveSeller = allVariantsHaveSeller(inStockVariants);

      setIsSoldByPartner(
        (doAllVariantsHaveSeller ||
          doesVariantHaveSeller(inStockVariants, selectedVariantId)) &&
          inStockVariants.length
      );
    }
  }, [product, selectedVariantId]);

  return (
    <>
      {showNewPromoMessages ? (
        <PromoBox />
      ) : (
        <>
          <div
            id="product-promo"
            data-testid="product-promo"
            className={classnames(styles.productPromo, {
              hidden: isSoldByPartner,
            })}
          />
          <div
            id="product-promo-default"
            data-testid="product-promo-default"
            className={classnames(styles.productPromo, {
              hidden: !isSoldByPartner,
            })}
          />
        </>
      )}
    </>
  );
};

export default ProductPromo;
